<script setup lang="ts">
import type { NovaBoxPostsSimpleProps } from './NovaBoxPostsSimple.types'
import type { HashtagPost } from '@store'
import { ROUTES } from '@configs'

defineProps<NovaBoxPostsSimpleProps>()

const route = useRoute()

const handleOpenPostDetail = (cmtyNttSn: HashtagPost['cmtyNttSn']) => {
  useNavigations({
    url: useRoutePathIdChange(ROUTES.HASHTAG_DETAIL.path, {
      id: String(cmtyNttSn),
    }),
    query: route.query,
  })
}
</script>

<template>
  <div class="simple-posts">
    <NovaBoxPostsSimpleItem
      v-for="post in posts"
      :key="post.cmtyNttSn"
      :post="post"
      @click="handleOpenPostDetail(post.cmtyNttSn)"
    />
  </div>
</template>

<style lang="scss" scoped>
.simple-posts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
