<script setup lang="ts">
import type { Hashtag } from '@store'
import { ROUTES } from '@configs'

const { locale } = useI18n()
const router = useRouter()
const postStore = usePostStore()
const etcStore = useEtcStore()
const activeHashtag = ref<Hashtag>({ hashtagNm: '', hashtagSn: -1 })
const isLoading = ref(false)
const isError = ref(false)
const isInit = ref(false)
const isHashtagPage = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.HASHTAG.name)
})

onMounted(() => {
  setTimeout(() => {
    initActiveHashtag()
  }, 600)
})

watch(
  () => activeHashtag.value,
  (cur) => {
    if (cur.hashtagSn === -1) return
    handleOnFetchPostsByHashtagRanking(cur)
  }
)

const initActiveHashtag = () => {
  if (isHashtagPage.value) {
    activeHashtag.value = postStore.hashtagControl.hashtag
    return
  }

  if (etcStore.hashtagRankingPrimary.length) {
    activeHashtag.value = etcStore.hashtagRankingPrimary[0]
  }

  isInit.value = true
}

const handleOnFetchPostsByHashtagRanking = async (hashtag: Hashtag) => {
  try {
    isLoading.value = true
    isError.value = false
    await postStore.fetchPostsByHashtagRanking({
      hashtagSn: hashtag.hashtagSn,
    })
  } catch {
    isError.value = true
  } finally {
    isLoading.value = false
  }
}

const handleOnChangeActiveHashtag = (hashtag: Hashtag) => {
  activeHashtag.value = hashtag
}

const handleOnGoHashtag = () => {
  router.push({
    path: locale.value === 'ko' ? '/hashtag' : `/${locale.value}/hashtag`,
    query: activeHashtag.value,
  })
}
</script>

<template>
  <NovaBoxBase class="box-hashtag-rankings">
    <div class="header">
      <div class="title-wrap">
        <h4 class="title">#{{ $t('hashtagRanking') }}</h4>
        <NovaButtonSimpleText
          v-if="!isHashtagPage"
          :label="$t('seeMore')"
          @click="handleOnGoHashtag"
        />
      </div>

      <ClientOnly>
        <NovaHashtagExtender
          :active-hashtag="activeHashtag"
          :hashtags="etcStore.hashtagRankingPrimary"
          :min-hashtags="5"
          :max-hashtags="10"
          @on-change-active-hashtag="handleOnChangeActiveHashtag"
        />
      </ClientOnly>
    </div>

    <ClientOnly>
      <div class="contents">
        <NovaBoxPostsSimple
          v-if="
            !isLoading && !isError && postStore.postsByHashtagRanking.length
          "
          :posts="postStore.postsByHashtagRanking"
        />

        <NovaBoxEmptyMessage
          v-if="
            !isLoading &&
            !isError &&
            isInit &&
            !postStore.postsByHashtagRanking.length
          "
          :message="
            $t('statusMessages.postsByHashtagSimple.empty', {
              hashtag: activeHashtag.hashtagNm,
            })
          "
        />
      </div>

      <NovaLoadingIndicator
        v-if="isLoading"
        :size="80"
        :fill="true"
        :bg-bright="'light'"
      />
    </ClientOnly>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.box-hashtag-rankings {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 !important;
  overflow: hidden;

  > .header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 20px 0;

    .title-wrap {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;

      > .title {
        flex-grow: 1;
        @include text-style($text-display-bold);
      }

      > .more {
        flex-shrink: 0;
      }
    }
  }

  .contents {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px 20px;
    height: 100%;
    overflow-y: overlay;
  }
}
</style>
