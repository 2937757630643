<script setup lang="ts">
import type { Hashtag } from '@store'
import { ROUTES } from '@configs'

const etcStore = useEtcStore()
const postStore = usePostStore()
const { hashtagRankingTertiary } = storeToRefs(etcStore)
const { hashtagControl } = storeToRefs(postStore)
const hashtagKeyword = ref('')
const filteredHashtags = computed(() =>
  hashtagRankingTertiary.value
    .slice(0, 50)
    .filter((hashtag) => hashtag.hashtagNm.includes(hashtagKeyword.value))
)

const handleOnChangeActiveHashtag = async (hashtag: Hashtag) => {
  await useNavigations({
    url: ROUTES.HASHTAG.path,
    query: { ...hashtag },
    type: 'replace',
  })
  setTimeout(() => {
    window.scrollTo({ top: 0 })
  }, 300)
}
</script>

<template>
  <NovaBoxBase class="hashtags-all">
    <div class="header">
      <NovaInput
        v-model="hashtagKeyword"
        :placeholder="$t('searchHashtag')"
        :theme="'search'"
      >
        <template #tools>
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'search' }"
            class="search-button"
          />
        </template>
      </NovaInput>

      <h4 class="title">#{{ $t('hashtag') }}</h4>

      <NovaDivider />
    </div>

    <div class="hashtags-container">
      <ClientOnly v-if="filteredHashtags.length">
        <NovaHashtags
          :hashtags="filteredHashtags"
          :prefix="'#'"
          :able-action="true"
          :select-hashtag="hashtagControl.hashtag"
          @on-change-active-hashtag="handleOnChangeActiveHashtag"
        />
      </ClientOnly>

      <ClientOnly v-else>
        <NovaBoxEmptyMessage
          :message="$t('statusMessages.hashtagRanking.empty')"
        />
      </ClientOnly>
    </div>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.hashtags-all {
  padding: 0 !important;
  overflow: hidden;

  .header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 20px 0;

    .title {
      @include text-style($text-display-bold);
    }
  }

  .hashtags-container {
    flex-grow: 1;
    padding: 15px 20px 20px;
  }
}
</style>
